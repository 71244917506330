/**
 * From the MF docs:
 * "
 * The application is eagerly executing an application that is operating as an omnidirectional host.
 * There are options to choose from:
 * You can set the dependency as eager inside the advanced API of Module Federation,
 * which doesn't put the modules in an async chunk,
 * but provides them synchronously.
 * This allows us to use these shared modules in the initial chunk.
 * But be careful as all provided and fallback modules will always be downloaded.
 *
 * It's recommended to provide it only at one point of your application, e.g. the shell.
 * We strongly recommend using an asynchronous boundary.
 * It will split out the initialization code of a larger chunk to avoid any additional round trips and improve performance in general.
 * "
 *
 * Documentation regarding a bootstrap file: https://webpack.js.org/concepts/module-federation/#troubleshooting
 */
import('./bootstrap').catch((err) => void console.error(err));
